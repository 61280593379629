"use client";

import { useEsiVars } from "@yahoo-creators/edge";
import { UH3 } from "@yahoo-creators/uh";
import { type Item } from "@yahoo-creators/uh/lib/l2-navigation-data";
import { usePathname } from "next/navigation";
import { type EdgeSideIncludes } from "@/configs/esi";
import { creatorsL2NavData, creatorsMailAppId } from "@/configs/header";
import { useRequestContext } from "@/lib/request/client";

export const SiteHeader = ({
  l2NavData = creatorsL2NavData,
  mailAppId = creatorsMailAppId,
}: {
  mailAppId?: string;
  l2NavData?: Item[];
  site?: string;
}) => {
  const { crumb } = useEsiVars<Pick<EdgeSideIncludes, "crumb">>({ crumb: "" });

  const pathname = usePathname();

  const subnavData: Item[] = l2NavData.map(({ name, subLinks = [], url }) => ({
    name,
    selected: url === pathname,
    subLinks,
    url,
  }));
  const { site, partner } = useRequestContext();
  return (
    <UH3
      mailAppId={mailAppId}
      partner={partner}
      site={site}
      lang="en-US"
      region="US"
      crumb={crumb}
      showL2Navigation={subnavData.length > 0}
      subnavData={subnavData}
    />
  );
};
